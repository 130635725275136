import fetch from '../utils/fetch'

export function getEnjoyGoodsPjAndYb (query) {
  return fetch({
    url: '/enjoy/goodspjyn',
    method: 'get',
    params: query
  })
}

export function getEnjoyGoodsFirstAmount (query) {
  return fetch({
    url: '/enjoy/goodsfirstamount',
    method: 'get',
    params: query
  })
}



export function getEnjoyContractPeriod (query) {
  return fetch({
    url: '/enjoy/contractperiod',
    method: 'get',
    params: query
  })
}


export function getEnjoyValidity (query) {
  return fetch({
    url: '/enjoy/getEnjoyValidity',
    method: 'get',
    params: query
  })
}

export function getDownPay(query) {
  return fetch({
    url: '/enjoy/get/down/payment',
    method: 'get',
    params: query
  })
}

